/* You can add global styles to this file, and also import other style files */
// @import url("https://fonts.googleapis.com/icon?family=Material+Icons");
//@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
html,
body {

  overflow: hidden;
}

html,
body {
  position: relative;
  height: 100%;
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;

  overflow: hidden;
}

// @import "~jsoneditor/dist/jsoneditor.min.css";

.contextmenuitem {
  font-size: 14px;
  padding: 1px 10px;
  cursor: pointer;
  color: black;
}


.contextmenuitem:hover,
.contextmenuitem.active {

  background-color: #8C30F5;
  color: white !important;
}


@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $candy-app-primary: mat-palette($mat-light-green, A400, A300, A800);
// $candy-app-accent:  mat-palette($mat-light-green, A400, A200, A800);
// $candy-app-warn:    mat-palette($mat-red);

// $my-custom-primary-color: (
//   50 : #edebfc,
//   100 : #d3cef8,
//   200 : #b6aef3,
//   300 : #988dee,
//   400 : #8274eb,
//   500 : #8e26f0,
//   600 : #6454e4,
//   700 : #594ae0,
//   800 : #4f41dd,
//   900 : #3d30d7,
//   A100 : #ffffff,
//   A200 : #e9e7ff,
//   A400 : #b9b4ff,
//   A700 : #a29bff,
//   contrast: (50 : #000000,
//     100 : #000000,
//     200 : #000000,
//     300 : #000000,
//     400 : #000000,
//     500 : #ffffff,
//     600 : #ffffff,
//     700 : #ffffff,
//     800 : #ffffff,
//     900 : #ffffff,
//     A100 : #000000,
//     A200 : #000000,
//     A400 : #000000,
//     A700 : #000000,
//   )
// );
// $my-custom-accent-color: (
//   50 : #fae6e6,
//   100 : #f3c1c1,
//   200 : #eb9898,
//   300 : #e26e6f,
//   400 : #dc4f50,
//   500 : #9f26f0,
//   600 : #d12b2c,
//   700 : #cc2425,
//   800 : #c61e1f,
//   900 : #bc1313,
//   A100 : #ffecec,
//   A200 : #ffb9b9,
//   A400 : #ff8686,
//   A700 : #ff6d6d,
//   contrast: (50 : #000000,
//     100 : #000000,
//     200 : #000000,
//     300 : #000000,
//     400 : #ffffff,
//     500 : #ffffff,
//     600 : #ffffff,
//     700 : #ffffff,
//     800 : #ffffff,
//     900 : #ffffff,
//     A100 : #000000,
//     A200 : #000000,
//     A400 : #000000,
//     A700 : #000000,
//   )
// );
// $ligna-app-primary: mat-palette($my-custom-primary-color);
// $ligna-app-accent: mat-palette($my-custom-accent-color);
// $ligna-app-warn: mat-palette($mat-red);

// $ligna-theme: mat-light-theme($ligna-app-primary, $ligna-app-accent, $ligna-app-warn);
// @include angular-material-theme($ligna-theme);

$my-custom-primary-color: (
  50 : #edebfc,
  100 : #d3cef8,
  200 : #b6aef3,
  300 : #988dee,
  400 : #8274eb,
  500 : #8e26f0,
  600 : #6454e4,
  700 : #594ae0,
  800 : #4f41dd,
  900 : #3d30d7,
  A100 : #ffffff,
  A200 : #e9e7ff,
  A400 : #b9b4ff,
  A700 : #a29bff,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
$my-custom-accent-color: (
  50 : #fae6e6,
  100 : #f3c1c1,
  200 : #eb9898,
  300 : #e26e6f,
  400 : #dc4f50,
  500 : #9f26f0,
  600 : #d12b2c,
  700 : #cc2425,
  800 : #c61e1f,
  900 : #bc1313,
  A100 : #ffecec,
  A200 : #ffb9b9,
  A400 : #ff8686,
  A700 : #ff6d6d,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
$ligna-app-primary: mat-palette($my-custom-primary-color);
$ligna-app-accent: mat-palette($my-custom-accent-color);
$ligna-app-warn: mat-palette($mat-red);

$ligna-theme: mat-light-theme($ligna-app-primary, $ligna-app-accent, $ligna-app-warn);
@include angular-material-theme($ligna-theme);




@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
// @tailwind base;
// @tailwind components;
// @tailwind utilities;


// Bug  described here: https://github.com/angular/components/issues/11765
mat-accordion mat-expansion-panel {
  mat-expansion-panel-header {
    height: 40px;
  }

  .mat-expansion-panel-content {
    height: 0;
  }

  &.mat-expanded {
    mat-expansion-panel-header {
      height: 64px;
    }

    .mat-expansion-panel-content {
      height: auto;
    }
  }
}


// @import '~@circlon/angular-tree-component/css/angular-tree-component.css';

@import '~handsontable/dist/handsontable.full.css';











@import '~@circlon/angular-tree-component/css/angular-tree-component.css';



body.darkmode ::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: rgb(55, 55, 55);
}

body.darkmode ::-webkit-scrollbar-thumb {
  background: #494949;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

body.darkmode ::-webkit-scrollbar-corner {
  background: rgb(71, 71, 71);
}




.btn-outline-primary:hover {
  color: #fff;
  background-color: #e4ccff;
  border-color: #8C30F5;
}



.mat-dialog-container {
  overflow: hidden !important;
  border-radius: 20px !important;
}


@import "~jsoneditor/dist/jsoneditor.min.css";

// regular style toast https: //github.com/scttcper/ngx-toastr
@import 'ngx-toastr/toastr';

// Light theme scrollbars
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background: #f0f0f0;
}

::-webkit-scrollbar-thumb {
  background: #cdcdcd;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

::-webkit-scrollbar-corner {
  background: #f0f0f0;
}

// Dark theme scrollbars
body.darkmode ::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background: rgb(55, 55, 55);
}

body.darkmode ::-webkit-scrollbar-thumb {
  background: #494949;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

body.darkmode ::-webkit-scrollbar-corner {
  background: rgb(71, 71, 71);
}

// Keep input fields light in dark theme
// body.darkmode {

//   // input:not([type="checkbox"]):not([type="radio"]):not([type="range"]),
//   // textarea,
//   // select {
//   //   background-color: #ffffff !important;
//   //   color: #000000 !important;
//   //   border: 1px solid #666666;
//   // }

//   // .mat-input-element,
//   // .mat-form-field-input {
//   //   background-color: #ffffff !important;
//   //   color: #000000 !important;
//   // }

//   // Ensure autocomplete dropdown remains readable
//   .autocompleteEditor .htCore {
//     background-color: #ffffff !important;
//     color: #000000 !important;
//   }
// }